<template>
  <v-container fluid class="loadingScreen pa-0">
    <v-row justify="center" align="center" class="main" no-gutters>
      <v-col cols="5" class="text-center">
        <img class="logo" src="/Image/logo_intro.png" alt />
      </v-col>
    </v-row>
    <loading v-show="loadingState"></loading>
  </v-container>
</template>

<script>
  import Loading from "../components/loading";
  export default {
    name: "LoadingScreen",
    components: {
      loading: Loading,
    },
    data: () => ({
      loadingState: false,
    }),
    mounted() {
      this.$nextTick(() => {
        setTimeout(async () => {
          this.$store.commit("SET_LOGIN", true);
          this.loadingState = true;
          const sessionToken = localStorage.getItem('sessionToken_casalapin');
          if (sessionToken == null) {
            if (localStorage.getItem("casa_lang") == null) {
              this.$router.push("/SelectLanguage");
            } else {
              this.$router.push("/PhoneVerify");
            }
          } else {
            await this.$store.dispatch("getEkycData");
            await this.$store.dispatch('getMe');
            await this.$router.push("/");
          }
          this.loadingState = false;
        }, 1000);
      });
    },
  };
</script>

<style lang="scss">
  .loadingScreen {
    height: 100vh;
    background-image: url("/Image/BG.png");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    .main {
      height: 100vh;
    }

    .logo {
      width: 100%;
      height: auto;
      max-width: 250px;
    }
  }
</style>